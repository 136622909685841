.shortcut-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-items: center;
}

.shortcut {
  padding: 12px;
  position: relative;
  background: rgb(255, 255, 255);
}

.shortcut:hover {
  background: rgb(230, 230, 230);
}

.shortcut-title {
  position: absolute;
  background: black;
  border-radius: 5px;
  padding: 2px 6px;
  max-width: 150px;
  overflow: hidden;
  text-align: left;
  top: -30px;
  z-index: 2;
  visibility: hidden;
}
.shortcut-title pre {
  font-family: 'Helvetica', sans-serif;
  text-overflow: ellipsis;
  word-break: keep-all;
  position: relative;
}

.shortcut-icon-container:hover .shortcut-close {
  visibility: visible;
  opacity: 1;
}

.shortcut:hover .shortcut-title,
.shortcut:hover .shortcut-down-arrow,
.shortcut:hover .shortcut-close {
  visibility: visible;
}

.shortcut-down-arrow {
  position: absolute;
  content: '';
  border: solid black;
  border-width: 0 3px 3px 0;
  padding: 3px;
  top: -15px;
  left: 42%;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  z-index: 3;
  visibility: hidden;
}

.shortcut-close {
  position: absolute;
  top: -5px;
  right: -20px;
  background: white;
  padding: 2px 2px;
  border-radius: 50000%;
  visibility: hidden;
  opacity: 0;
  transition: 0.2s;
}

.shortcut-close:hover {
  background: rgb(230, 230, 230);
}

.shortcut-close svg {
  width: 16px;
  height: 16px;
}

.shortcut-close svg path {
  color: black;
  stroke-width: 1;
}

.shortcut-option {
  display: none;
  position: absolute;
  top: 10px;
  right: 0px;
  padding: 2px;
}

.shortcut:hover .shortcut-option,
.shortcut-option-open {
  display: flex;
  justify-items: flex-end;
}

.shortcut:hover .shortcut-option svg path {
  fill: #fff;
}

.shortcut:hover .shortcut-option:hover {
  background-color: grey;
}

.addShortcut {
  width: 56px;
  height: 56px;
  display: grid;
  place-items: center;
  background: rgb(255, 255, 255);
  border-radius: 100%;
  font-size: 1.8rem;
  color: rgba(35, 35, 35, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.addShortcut:hover {
  background: rgb(230, 230, 230);
}

.perso {
  max-width: 720px;
  width: 75%;
}

.falash {
  height: auto;
  border-radius: 100%;
  transition: 0.3s;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
  border: 1px solid rgba(35, 35, 35, 0.2);
}
