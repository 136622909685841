.suggested-city-item:hover {
  background-color: antiquewhite;
}

.suggested-city-item--selected {
  background-color: burlywood;
}

.temperature-options {
  padding: 5px;
  transition: all 0.5s;
  border-left: 1px;
  border-style: solid;
  border-color: black;
  min-width: 32px;
}

.temperature-options:hover {
  background-color: #edf8ff;
}

.temperature-options:active {
  background-color: #338fc9;
  color: white;
}

.temperature-options__options {
  top: 35px;
  right: 0px;
  border-top: 1px solid #fff;
  background-color: #338fc9;
  padding: 5px 7px;
  @apply rounded-lg;
  cursor: pointer;
}
