.notplaying svg path {
  fill: white;
}

.youtube-search svg path {
  fill: #333;
}

.common-youtube-width {
  min-width: 250px;
  border-radius: 0.5rem 0.5rem;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 6px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px;
}

.undo-button {
  background: linear-gradient(90deg, #338fc9, #232323, #338fc9);
  background-size: 300%;
}

.undo-button:hover {
  background: linear-gradient(180deg, #1f78af, #1f78af);
}

.confirm-button {
  background: linear-gradient(90deg, rgba(121, 9, 44, 1), rgba(255, 0, 46, 0.8337710084033614));
  background-size: 300%;
}

.confirm-button:hover {
  background: linear-gradient(90deg, rgba(255, 0, 46, 0.8337710084033614), rgba(121, 9, 44, 1));
}
