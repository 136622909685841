.has-reminder-svg {
  display: flex;
  align-items: center;
  gap: 3px;
}
.has-reminder-svg svg path {
  fill: blue;
}

.brown-dot {
  width: 10px;
  height: 10px;
  background-color: blue;
  border-radius: 999999px;
}

.has-current-reminder svg path {
  fill: brown;
}

.has-current-reminder .brown-dot {
  background-color: brown;
}
