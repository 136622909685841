.weeks-container {
  display: grid;
  grid-template-columns: repeat(7, 130px);
}

.calendar-day .calendar-day-add-btn {
  visibility: hidden;
  opacity: 0;
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.calendar-day:hover .calendar-day-add-btn {
  visibility: visible;
  opacity: 1;
  cursor: pointer;
}

.event-prev-tab {
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 60px;
}

.event-prev-tab:hover {
  background: rgb(230, 230, 230);
}
